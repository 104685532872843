
export default {
    data () {
        return {
            items: [
                {
                    icon: 'house',
                    title: this.$t('WHY.HIGH_YIELD'),
                    description: this.$t('WHY.HIGH_YIELD_DESCRIPTION'),
                },
                {
                    icon: 'hands_house',
                    title: this.$t('WHY.HIGH_DEMAND'),
                    description: this.$t('WHY.HIGH_DEMAND_DESCRIPTION'),
                },
                {
                    icon: 'house_percentage',
                    title: this.$t('WHY.TAX_FREE'),
                    description: this.$t('WHY.TAX_FREE_DESCRIPTION'),
                },
                {
                    icon: 'house_lock',
                    title: this.$t('WHY.SAFE_AND_SECURE'),
                    description: this.$t('WHY.SAFE_AND_SECURE_DESCRIPTION'),
                },
                {
                    icon: 'regulations',
                    title: this.$t('WHY.REGULATIONS'),
                    description: this.$t('WHY.REGULATIONS_DESCRIPTION'),
                },
                {
                    icon: 'world',
                    title: this.$t('WHY.TRAVEL'),
                    description: this.$t('WHY.TRAVEL_DESCRIPTION'),
                },
                {
                    icon: 'buildings',
                    title: this.$t('WHY.STRUCTURE'),
                    description: this.$t('WHY.STRUCTURE_DESCRIPTION'),
                },
                {
                    icon: 'visa',
                    title: this.$t('WHY.VISA'),
                    description: this.$t('WHY.VISA_DESCRIPTION'),
                },
            ],
        }
    },
}
