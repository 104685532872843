import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=639a8331&scoped=true&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639a8331",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/usr/src/app/components/ContactForm.vue').default})
