
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import leftArrow from '~/assets/images/icons/arrow-left.svg?raw'
import stars from '~/assets/images/icons/stars.svg?raw'

export default {
    components: {
        VueperSlides,
        VueperSlide,
    },
    data () {
        return {
            leftArrow,
            stars,
            breakpoints: {
                764: {
                    slideRatio: 2,
                },
                1440: {
                    slideRatio: 0.55,
                },
            },
            items: [
                {
                    image: 'person_1',
                    name: this.$t('TESTIMONIALS.PERSON_1_NAME'),
                    text: this.$t('TESTIMONIALS.PERSON_1_TEXT'),
                },
                {
                    image: 'person_2',
                    name: this.$t('TESTIMONIALS.PERSON_2_NAME'),
                    text: this.$t('TESTIMONIALS.PERSON_2_TEXT'),
                },
                {
                    image: 'person_3',
                    name: this.$t('TESTIMONIALS.PERSON_3_NAME'),
                    text: this.$t('TESTIMONIALS.PERSON_3_TEXT'),
                },
            ],
        }
    },
}
