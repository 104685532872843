import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0b857363&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageHero: require('/usr/src/app/components/homepage/Hero.vue').default,HomepagePartners: require('/usr/src/app/components/homepage/Partners.vue').default,HomepageServices: require('/usr/src/app/components/homepage/Services.vue').default,HomepageOpportunities: require('/usr/src/app/components/homepage/Opportunities.vue').default,HomepageWhy: require('/usr/src/app/components/homepage/Why.vue').default,HomepageConsultant: require('/usr/src/app/components/homepage/Consultant.vue').default,HomepageTestimonials: require('/usr/src/app/components/homepage/Testimonials.vue').default,FooterComponent: require('/usr/src/app/components/FooterComponent.vue').default,FloatingAgent: require('/usr/src/app/components/floating/Agent.vue').default})
