
export default {
    data () {
        return {
            items: [
                {
                    icon: 'search',
                    title: this.$t('SERVICES.EXPERT_SEARCHES'),
                    description: this.$t('SERVICES.EXPERT_SEARCHES_INFO'),
                },
                {
                    icon: 'analysis',
                    title: this.$t('SERVICES.ANALYSIS'),
                    description: this.$t('SERVICES.ANALYSIS_INFO'),
                },
                {
                    icon: 'offer',
                    title: this.$t('SERVICES.LIMITED_OFFER'),
                    description: this.$t('SERVICES.LIMITED_OFFER_INFO'),
                },
            ],
        }
    },
}
