
export default {
    data () {
        return {
            partners: [
                'aziz',
                'emaar',
                'jumeirah',
                'damac',
                'arabian_ranches',
            ],
        }
    },
}
