
import qs from 'qs'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import leftArrow from '~/assets/images/icons/arrow-left.svg?raw'

const formatter = Intl.NumberFormat('en',
    {
        style: 'currency',
        currency: 'AED',
        notation: 'compact',
        maximumFractionDigits: 0,
    })

export default {
    components: {
        VueSlickCarousel: () => import('vue-slick-carousel'),
    },
    data () {
        return {
            leftArrow,
            slickOptions: {
                slidesToShow: 3,
                focusOnSelect: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 764,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ],
            },
            properties: [],
        }
    },
    async fetch () {
        const response = await this.$axios({
            url: 'properties',
            params: { filter: { featured: 1 }, include: 'media' },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),

        })
        this.properties = response.data.data
    },
    methods: {
        format (price) {
            return formatter.format(price)
        },
    },
}
