
const numberQuestions = 8

export default {
    data () {
        return {
            questions: Array.from(Array(numberQuestions).keys()).map(i => ({
                question: this.$t(`FOOTER.QUESTION_${i + 1}`),
                answer: this.$t(`FOOTER.ANSWER_${i + 1}`),
                open: false,
            })),
        }
    },
}
