import { render, staticRenderFns } from "./FooterComponent.vue?vue&type=template&id=044977de&scoped=true&"
import script from "./FooterComponent.vue?vue&type=script&lang=js&"
export * from "./FooterComponent.vue?vue&type=script&lang=js&"
import style0 from "./FooterComponent.vue?vue&type=style&index=0&id=044977de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044977de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FAQsComponent: require('/usr/src/app/components/FAQsComponent.vue').default,RequestComponent: require('/usr/src/app/components/RequestComponent.vue').default})
