export default {
    data () {
        return { agent: null }
    },
    async mounted () {
        await this.getAgent()
    },
    methods: {
        async getAgent () {
            const agent = localStorage.getItem('thrive_agent')
            if (agent && Object.keys(agent).length > 0) {
                this.agent = JSON.parse(agent)
            } else {
                const agentResponse = await this.$axios({
                    url: 'rotation',
                    params: { language_iso: this.$i18n.locale, agent_id: this.$route.query.agent_id },
                })
                this.agent = agentResponse.data
                localStorage.setItem('thrive_agent', JSON.stringify(this.agent))
            }
        },
        call () {
            const link = document.createElement('a')
            link.href = `tel:${this.agent.phone}`
            link.click()
        },
        email () {
            const link = document.createElement('a')
            link.href = `mailto:${this.agent.email}`
            link.target = '_blank'
            link.click()
        },
        sendTelegramMessage () {
            window.open(this.agent.telegram, '_blank')
        },
        sendWhatsAppMessage () {
            window.open(
                `https://api.whatsapp.com/send?phone=${this.agent.whatsapp}&text=${
                    this.$t('WHATS_APP.TEXT')}`,
                '_blank',
            )
        },
    },
}
