
import getAgent from '@/mixins/getAgent'

export default {
    mixins: [getAgent],
    data () {
        return {
            isOpen: null,
        }
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll () {
            const h = document.documentElement
            const b = document.body
            const st = 'scrollTop'
            const sh = 'scrollHeight'

            const percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100
            if (percent > 33) {
                this.isOpen = window.innerWidth > 768
                window.removeEventListener('scroll', this.handleScroll)
            }
        },
    },
}
