
import handleToken from '@/mixins/handleToken'
import getAgent from '@/mixins/getAgent'

export default {
    mixins: [handleToken, getAgent],
    props: {
        showFaqs: {
            type: Boolean,
            default: true,
        },
        showBanner: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        async sendWhatsAppMessage () {
            window.open(
                `https://api.whatsapp.com/send?phone=${
                    this.$t('WHATS_APP.PHONE_NUMBER')}&text=${
                    this.$t('WHATS_APP.TEXT')}`,
                '_blank',
            )
            this.$gtm.push({ event: 'contactViaWA' })
            await this.$axios({
                method: 'post',
                url: 'started_chat',
                baseURL: '/',
                data: this.getToken(),
            })
        },
    },
}
